@import "../../../../styles/globals";

$icon-size: 48px;

.main__bio-socials {
  display: flex;
  height: 90vh;
  position: absolute;
  align-items: center;
  left: 16px;
  transition: $global-load 0.1s;
}

.main__bio-socials-links {
  display: flex;
  flex-direction: column;
}

.main__bio-socials-link {
  margin-bottom: 16px;
}

.main__bio-socials-icon {
  height: 100%;
  background: black;
  border: 1px solid black;
  border-radius: 10px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.main__bio-socials-link, .main__bio-socials-icon {
  display: flex;
  width: $icon-size;
  height: $icon-size;
  transition: height $global-load, width $global-load;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main__bio-socials-link:last-child {
  margin-bottom: 0;
}
