@import url("https://fonts.googleapis.com/css?family=Noto+Sans");

$global-load: ease-in-out .25s;
$global-load-slow: ease-in-out .5s;

$ms: 320px;
$mm: 375px;
$ml: 425px;
$t: 768px;
$l: 1024px;
$xl: 1440px;
$xxl: 2560px;

@mixin breakpoint($point) {
  @if $point == $xxl {
    @media (min-width: $xxl) { @content ; }
  }
  @else if $point == $xl {
    @media (min-width: $xl) { @content ; }
  }
  @else if $point == $l {
    @media (min-width: $l) { @content ; }
  }
  @else if $point == $t {
    @media (min-width: $t) { @content ; }
  }
  @else if $point == $ms {
    @media (max-width: $ms) { min-width: $ms; @content ; }
  }
}
