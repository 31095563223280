@import "../../../styles/globals";

.crosshair-card-container {
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 8px;
  transition-duration: 0.5s;
}

.crosshair-card-container:hover {
  cursor: pointer;
}

.crosshair-card-content {
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1;
  width: 128px;

  @include breakpoint($l) {
    width: 196px;
  }
}
