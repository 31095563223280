@import "../../styles/globals";

$background-colour: #282828;
$framework-blue-dark: #0D3962;
$framework-blue: #28659E;
$framework-blue-light: #5E97CB;
$framework-blue-lightest: #92C3EF;
$framework-blue-gradient: linear-gradient(90deg, $framework-blue 0%, $framework-blue-light 100%);


.main-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    background: $background-colour;
    color: white;
    font-family: Georgia, serif;
    justify-content: center;
}

.main-content {
    overflow: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.main-content .main-section {
    scroll-snap-align: start
}

.main-content-vertical-nav {
    z-index: 1000;
    right: 0;
    height: 100vh;
    position: fixed;
    transition: opacity $global-load;
}

.main-section {
    display: flex;
    width: 100vw;
    height: 100vh;
    background: white;
}

.main-section-body {
    display: flex;
    position: relative;
    flex: 1 1;
    transition: opacity $global-load-slow;
}

.main-projects-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex: 1;
    color: black;
}

.main-main-section-title-container {
    display: flex;
    height: 100%;
    right: 0;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
}

.main-main-section-title {
    color: darkgray;
    margin: 64px 16px 0;
    writing-mode: vertical-rl;
    font-size: 128px;
}

.main-projects-section-title-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    position: absolute;
    align-self: start;
}

.main-projects-section-title {
    color: darkgray;
    margin: 64px 0 0;
    writing-mode: vertical-rl;
    transform: scale(-1, -1);
    font-size: 128px;
}

