@import "../../../styles/globals";

.main__bio {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1;
  user-select: none;
}

.main__bio-logo {
  position: absolute;
  transition: $global-load;
  width: 48px;
  left: 32px;
  top: 32px;
}

.main__bio-profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: black;
  justify-content: center;
  align-items: center;
}

.main__bio-profile-center {
  display: flex;
  position: relative;
}

.main__bio-profile__center-piece {
  background: black;
  width: 256px;
  height: 256px;
  padding: 8px;
  color: white;
  //animation-name: center-piece-float;
  //animation-duration: 4s;
  //animation-iteration-count: infinite;
}

.main__bio-profile__center-piece p {
  font-weight: 700;
  font-size: 70px;
  line-height: 85px;
  letter-spacing: 0.15em;
  margin: 0;
}

.main__bio-socials, .main__bio-description {
  margin-top: 12px;
}

.main__bio-description {
  font-size: 16px;
}
