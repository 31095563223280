.valorant-container {
    width: 100vw;
    height: 100vh;
}

.valorant-header {
    display: flex;
}

.valorant-create {

}

.valorant-content {
    display: flex;
    flex-wrap: wrap;
    margin-left: 18px;
}
