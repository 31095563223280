@import "../../../styles/globals";

$mode-shift-duration: 0.5s;

.main__bio-nav {
  display: flex;
  width: 500px;
  opacity: 0;
  position: relative;
  align-items: center;
  margin-top: 64px;
  font-family: 'Noto-Sans', sans-serif;

  transition: width $global-load, opacity $global-load;

  @include breakpoint($t) {
    opacity: 1;
  }

  @include breakpoint($l) {
    width: 800px;
  }

  @include breakpoint($xl) {
    width: 900px;
  }
}

.main__bio-nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 16px;
  margin-right: 16px;
}

.main__bio-nav-bar-link {
  z-index: 100;
  padding: 4px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  transition: text-shadow $global-load;
}

.main__bio-nav-bar-link:hover {
  text-shadow: 1px 2px 4px rgba(.95, .95, .95, 0.15);
}

.main__bio-nav-line {
  width: 100%;
  height: 1px;
}

.main__bio-nav-line--end {
  width: 5%;
  height: 1px;
}

.main__bio-nav-container--vertical {
  display: flex;
  align-items: center;
  font-family: 'Noto-Sans', sans-serif;
}

.main__bio-nav--vertical {
  display: flex;
  position: absolute;
  height: 75vh;
  right: 32px;
}

.main__bio-nav-bar--vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  transition: $mode-shift-duration ease-in-out;
}

.main__bio-nav-bar--vertical-link {
  z-index: 100;
  padding: 4px;
  text-decoration: none;
  writing-mode: vertical-lr;
  font-size: 18px;
  transition: $mode-shift-duration ease-in-out, text-shadow $global-load;
}

.main__bio-nav-bar--vertical-link:hover {
  text-shadow: 1px 2px 4px rgba(.95, .95, .95, 0.15);
};

.main__bio-nav-line--vertical {
  width: 1px;
  height: 100%;
  transition: $mode-shift-duration ease-in-out;
}

.main__bio-nav-line--vertical--end {
  width: 1px;
  height: 5%;
  transition: $mode-shift-duration ease-in-out;
}
