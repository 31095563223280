.valorant-create-container {
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}

.valorant-create-content {
  display: flex;
  justify-content: center;
}

.valorant-create-content--settings {
}
