@import url("https://fonts.googleapis.com/css?family=Noto+Sans");

html,
body {
  overflow: hidden;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Noto-Sans', sans-serif;
  font-size: 32px;
  font-weight: 400;
}

h2 {
  font-family: 'Noto-Sans', sans-serif;
  font-size: 24px;
  font-weight: 600;
}

p {
  font-family: 'Noto-Sans', sans-serif;
}

a {
  font-family: 'Noto-Sans', sans-serif;
  text-decoration: none;
  color: black;
}
