.crosshair-create-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  padding: 0 12px;
  margin-bottom: 12px;
}

.crosshair-create-section-title {
  align-self: start;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.crosshair-create-main, .crosshair-create-section {
  width: 100%;
}

.crosshair-create-section {
  background: #dedede;
  border-radius: 4px;
  padding: 12px;
}

.crosshair-create-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.crosshair-create-title {
  align-self: center;
  width: 300px;
}

.crosshair-create-buttons {
  display: flex;
}

.crosshair-create-button {
  font-weight: 700;
  font-size: 24px;
  padding: 4px 48px;
  background: lightgray;
  margin-left: 12px;
  transition-duration: 0.1s;
  cursor: pointer;
}

.crosshair-create-slider, .crosshair-create-drop-down-list {
  flex: 1;
}

.crosshair-create-drop-down-list {
  position: relative;
  border-radius: 2px;
  margin-bottom: 8px;
}

.crosshair-create-drop-down-button {
  width: 100%;
  background: white;
  text-align: center;
  border: 1px solid #757575;
  border-radius: 2px;
}

.crosshair-create-colour-modal {
}

.crosshair-create-colour-select {
  width: 100%;
  background: white;
  text-align: center;
  border: 1px solid #9a9a9a;
  border-radius: 2px;
}
