.crosshair-lines {
  position: relative;
  display: flex;
  z-index: 100;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
}

.crosshair-line {
  position: absolute;
}

.crosshair-lines-outlines {
  position: relative;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
}

.crosshair-line-outline {
  position: absolute;
}
